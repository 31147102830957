html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

@-ms-viewport {
  width: device-width;
}

body {
  background: #f6f6f6;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  margin: 0;
  line-height: 1.42857143;
  color: #333;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  line-height:1.1;
}

h2 {
  line-height: 1.3em;
  font-weight: 400;
  color: #1a1a1a;
  font-size: 36px;
  margin-bottom: 30px;
}

h2 a {
  color: #1a1a1a;
}

h3 {
  font-size: 30px;
  font-weight: normal;
  font-weight: 400;
  margin-bottom: 30px;
}

h4 {
  font-weight: 400;
  font-size: 22px;
  color: #4d4d4d;
}

h5 {
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 20px;
  color: #6e6e6e;
  font-weight: 400;
}

h6 {
  font-size: 1.5rem;
}

p, .text {
  color: #2e3137;
  font-weight: 400;
  line-height: 1.7em;
  font-size: 16px;
  margin: 0 0 10px;
}

.text h4 {
  font-size: 18px;
  font-weight: 400;
  color: #2e3137;
  text-decoration: underline;
  margin-bottom: 20px;
  margin-top: 20px;
}

img {
  vertical-align: middle;
  border: none;
}

a {
  color: #b565a7;
  background: transparent;
  text-decoration: none;
}

a:active, a:hover {
  outline: 0;
}

a:hover {
  text-decoration: underline;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

hr {
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  border: 0;
  border-top: 1px solid #e7e7e7;
  margin: 30px 0;
}

hr.small {
  margin: 30px 0;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}

button {
  overflow: visible;
}

button, input[type="button"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], input[disabled] {
  cursor: default;
}

input, button, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

td, th {
  padding: 0;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  width: 160px;
  font-size: 16px;
  color: #fff;
  background: #b565a7;
}

.btn:hover, .btn:focus {
  background: #d674c0;
  color: #fff;
  text-decoration: none;
}

.btn:active {
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
  box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
}

.btn-secondary {
  width: auto;
  background:#D8ACD0;
}

#newsletter {
  color: #999;
}

#newsletter button, #newsletter input {
  padding:2px;
}

#newsletter button {
    width: 120px;
    vertical-align: baseline;
}

#newsletter .form {
  display: inline;
}

@media (max-width:991px) {
 #newsletter .form {
    display: inline;
  }

  #newsletter button {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width:640px) {
  #newsletter {
    text-align: center;
  }
  #newsletter .form {
    display: block;
  }

  #newsletter label {
    display: block;
  }

  #newsletter label span {
    display: none;
  }
}
 
#footer-message {
  z-index:1000;
  position: absolute;
  position: fixed;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4px;
  color: #fff;
  background: #b565a7;
  opacity: 0.9;
  border-top:1px solid #fff;
  text-align: center;
}

#footer-message:hover {
  text-decoration: none;
}

#footer-message span.link {
  color:yellow;
  text-decoration: underline;
}

@font-face {
  font-family: 'fontello';
  src: url('/assets/fonts/fontello.eot?10894523');
  src: url('/assets/fonts/fontello.eot?10894523#iefix') format('embedded-opentype'),
       url('/assets/fonts/fontello.woff?10894523') format('woff'),
       url('/assets/fonts/fontello.ttf?10894523') format('truetype'),
       url('/assets/fonts/fontello.svg?10894523#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?10894523#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

/***************************************************************************/
/* GLOBAL SECTION */
/***************************************************************************/

.container {
  margin-right: auto;
  margin-left: auto;
}

@media (max-width:568px) {
  #footer-message span.extra {
    display: none;
  }
}

@media (max-width:767px) {
  .hide-mobile {
    display: none !important;
  }
  #content {
    margin-left: 15px;
    margin-right: 15px;
    padding: 20px !important;
  }
  h2 {
    font-size: 26px;
    margin-bottom: 20px !important;
  }
  h3 {
    font-size: 24px;
  }
  .meta-info {
    margin: 20px 0 !important;
  }
  .blocks li a {
      width: 163px !important;
  }
}

@media (min-width:768px) {
  .container {
    width: 750px;
  }
    .blocks li a {
      width: 153px !important;
  }
}

@media (min-width:992px) {
  .container {
    width: 970px;
  }
  .blocks li a {
      width: 162px !important;
  }
}

@media (min-width: 1100px) {
  .blocks li a {
    width: 142px !important;
  }
  .container {
    width: 1070px ;
  }
}
@media (min-width:1280px) {
  .container {
    width: 1250px;
  }
}

.container:before,
.container:after {
  display: table;
  content: " ";
}
.container:after {
  clear: both;
}


/***************************************************************************/
/* HEADER SECTION */
/***************************************************************************/

#header {
  position: relative;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 5px #eaeaea;
  -moz-box-shadow: 0 1px 5px #eaeaea;
  -ms-box-shadow: 0 1px 5px #eaeaea;
  -o-box-shadow: 0 #px 5px #eaeaea;
  box-shadow: 0 1px 5px #dcdddd;
  padding: 10px;
  behavior: url(/assets/pie.htc);
}

#logo img {
  height: 30px;
  width: auto;
}

/***************************************************************************/
/* CONTENT SECTION */
/***************************************************************************/

#content, #sidebar {
   margin-top: 20px;
   margin-bottom: 20px;
}

#content {
  background: #fff;
  padding: 40px 50px;
  -webkit-box-shadow: 1px 3px 10px 0 #eaeaea, -1px 3px 10px 0 #eaeaea;
  -moz-box-shadow: 1px 3px 10px 0 #eaeaea, -1px 3px 10px 0 #eaeaea;
  -ms-box-shadow: 1px 3px 10px 0 #eaeaea, -1px 3px 10px 0 #eaeaea;
  -o-box-shadow: 1px 3px 10px 0 #eaeaea, -1px 3px 10px 0 #eaeaea;
  box-shadow: 1px 3px 10px 0 #eaeaea, -1px 3px 10px 0 #eaeaea;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  position: relative;
  min-height: 1px;
  behavior: url(/assets/pie.htc);
}

@media (min-width:992px) {
  #content {
    float: left;
    width: 79.16666667%;
  }
}

#content p {
  font-size: 18px;
}

h2 a:hover {
  color: #b565a7;
}

.text {
  font-size: 18px;
  margin-bottom: 40px;
}

.text p {
  margin-bottom: 20px;
}

p.action {
  font-style: italic;
}

.text h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 30px;
}

#vow span {
  color: #9d5791;
  cursor: pointer;
}

#vow span:hover {
  border-bottom: 1px dashed #d673c0;
}

#vow .comments {
  color:#999;
}

#vow .author {
  font-style: italic;
}

span.action {
  font-style: italic;
  cursor: text;
  color: #2e3137;
}
span.action:hover {
  border-bottom: none;
}

span.speaker {
  cursor: text; 
  color: #2e3137;
}
span.speaker:hover {
  border-bottom: none;
}

#intro p {
  font-size: 16px !important;
}
.meta-info {
  margin: 30px 0;
  list-style: none;
  padding: 3px;
}

.meta-info li {
  display: inline-block;
  display: inline\9;
  margin-left: 0;
}

.meta-info li:first-child {
  margin-left: 0;
}

.meta-info a, a.btn-share {
  display: inline-block !important;
  padding: 8px 12px;
  border-radius: 2px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  vertical-align: middle;
}

.meta-info a:hover, a.btn-share:hover {
  text-decoration: none;
}

.meta-info a.category {
  background:#b565a7;
  text-transform: uppercase;
}

.meta-info a.category:hover {
  background: #d674c0;
}
.meta-info a.inline {
  color: #b565a7;
  padding: 0;
}

.meta-info a.inline:hover {
  text-decoration: underline;
}

a.btn-share {
  padding: 8px 12px 8px 34px;
  text-align: center;
}

a.btn-share.icon {
  font-size: 22px;
  padding: 2px;
}

.btn-share.facebook:before { content: '\e800'; } /* '' */
.btn-share.twitter:before { content: '\e801'; } /* '' */
.btn-share.pinterest:before { content: '\e802'; } /* '' */
.btn-share.mail:before { content: '\e803'; } /* '' */

.btn-share.mail {
  background:#078500;
}

.btn-share.mail:hover {
  background: #099e01;
}

.btn-share.pinterest {
  background: #ce1e1d;
}

.btn-share.pinterest:hover {
  background: #ed3e18;
}
.btn-share.facebook {
  background:#3b5998;
}

.btn-share.twitter {
  background:#00aced;
}

.btn-share.facebook:hover {
  background-color: #4c70ba
}

.btn-share.facebook:active {
  background-color: #36528c
}

.btn-share.twitter:hover {
  background-color: #21c2ff
}

.btn-share.twitter:active {
  background-color: #00a0dc
}

/***************************************************************************/
/* COMMENT AREA */
/***************************************************************************/
.comments-area {
  margin-bottom: 30px;
}

.comments-area p {
  color: #818896;
  font-size: 16px !important;
}

.comments-area p.title {
  font-weight: 600;
}

.comments-area h3, .comments-area h4, .comments-area h5 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
  color: #818896;
  font-style: italic;
}

.comments-area span.author {
  color: #818896;
  font-weight: 600;
  margin-right: 30px;
}

.comments-area span.date {
  color: #abb0b9;
  margin-right: 20px;
}

.comments-area span.link {
  margin-right: 20px;
}

.comments-area hr.small {
  margin: 10px 0;
}

.comments-area .comment-form {
  margin-top: 30px;
}

.comments-area .comment-form h3 {
  margin-bottom: 30px;
}

form .form-group {
  margin-bottom: 10px;
}

form .form-input {
  background: #f5f5f5;
  border: none;
  outline: none;
  border: 1px solid #f0f0f0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  padding: 10px 8px;
}

form input[type="text"], form input[type="email"], form input[type="password"], form select {
  width: 40%;
}

form textarea.form-input {
  width: 100%;
}

/***************************************************************************/
/* PAGINATION */
/***************************************************************************/
.navigate-page {
  text-align: right;
  list-style: none;
  padding: 0;
  margin: 35px 0;
}

.navigate-page li {
  display: inline-block;
  background: #e6e6e6;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  margin-right: 5px;
}

.navigate-page li a {
  display: block;
  color: #686f7c;
  font-weight: 400;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
}

.navigate-page li.previous, .navigate-page li.next {
  width: 100px;
}

.navigate-page li:last-child {
  margin-right: 0;
}

.navigate-page li:hover a, .navigate-page li.active a {
  color: #fff;
  text-decoration: none;
}

.navigate-page li:hover, .navigate-page li.active {
  background: #b565a7;
}

/***************************************************************************/
/* SIDEBAR
/***************************************************************************/

#sidebar {
  position: relative;
  min-height: 1px;
}

@media (min-width:992px) {
  #sidebar {
      float: left;
      width: 20.83333333%;
  }
}

@media (max-width:991px) {
  #sidebar {
    display: none !important;
  }
}

#sidebar > ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#sidebar > ul > li {
  line-height: 1.5em;
  padding: 10px 15px 10px 0;
  padding-right: 0;
}

#sidebar > ul > li h4 {
  padding-left: 20px;
  line-height: 1.3em;
  color: #1a1a1a;
}

#sidebar > ul > li h4:hover a {
  color: #fff;
}

#sidebar .vows li:hover {
  background: #b565a7;
}

#sidebar .ceremony li:hover {
  background: #6eb4e3;
}

#sidebar .bible li:hover {
  background: #f4b756;
}

#sidebar .poems li:hover {
  background: #a1d48d;
}

#sidebar .articles li:hover {
  background: #eb7f73;
}

#sidebar .other li:hover {
  background: #6eb4e3;
}

#sidebar li.other {
  padding-bottom: 0;
}

#sidebar > ul > li a {
  display: block;
}

#sidebar > ul > li ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#sidebar > ul > li ul li {
  padding-left: 20px;
}

#sidebar > ul > li ul li a {
  padding: 5px 0;
  display: block;
  color: #6e6e6e;
}

#sidebar > ul > li ul li:hover a {
  color: #fff;
}

#sidebar a:hover {
  text-decoration:none;
}

/***************************************************************************/
/* PREV/NEXT POST */
/***************************************************************************/

.post-navigation {
  display: table;
  width: 100%;
  border: 1px solid #f0f0f0;
  background: #fafafa;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 40px;
  overflow: hidden;
}

.post-navigation:after {
  clear:both;
  display:block;
  content:'';
}

.post-navigation > a, .post-navigation > div {
  display: table-cell;
  width: 50%;
  vertical-align: top;
}

.post-navigation > a:hover {
  background:#b565a7;
}

.post-navigation > a:hover div, .post-navigation > a:hover div span {
  color: #fff;
}

.link-title-previous, .link-title-next {
    padding: 30px 0 25px 0;
    color: #818896;
}

.link-title-previous {
    padding-left: 25px;
}

.link-title-next {
    padding-right: 25px;
    float: right;
}

.link-title-previous, .prev-post-title {
    float: left;
}

.prev-post-title, .next-post-title {
    width: 100%;
    font-size: 18px;
    margin-top: 5px;
}

.link-title-previous span.nav, .link-title-next span.nav {
    color: #444;
    float: left;
    border-bottom: 1px solid;
    text-transform: uppercase;
    line-height: 22px;
    font-size: 14px;
}

.link-title-next span.nav, .next-post-title {
    float: right;
    text-align: right;
}

/***************************************************************************/
/* GLOBAL SECTION */
/***************************************************************************/

.hidden {
  display: none;
}

.yes, .no, .errors {
  padding: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  color: #fff;
  margin-bottom: 30px;
}

.yes {
  background: #a1d48d;
}

.yes a, .no a, .errors a {
  color: #ffff00;
  text-decoration: underline;
}

.no, .errors {
  background: #eb7e73;
}

.errors .title {
  font-size: 20px;
  color: #fff;
}

.errors p {
  font-size: 16px;
  color: #fff;
  margin-top: 10px;
}

.errors ul {
  list-style: disc outside none;
  padding-left: 30px;
  font-size: 16px;
}
/* http://techcrunch.com/2015/05/19/meteor-raises-20m-to-build-the-one-javascript-stack-to-rule-them-all/#.2o3ush:9JrW Facebook */
/* TODO UP / DOWN LIKE 9GAG.COM */
/* http://www.coindesk.com/tembusu-denies-varoufakis-conflict-advisor/*/

.listings h3 {
  font-size: 16px;
  margin-bottom: 10px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  color: #818896;
  font-weight: 400;
  height: 20px;
}

.listings h3 span {
  font-weight: normal;
}

.listings h3 a {
  font-size: 18px;
  font-weight: 400;
}

.listings .preview {
  font-size: 16px;
  color: #818896;
}

.listings hr {
  margin: 15px 0;
}

.continue {
  display: inline-block;
  background: #b565a7;
  color: #fff;
  padding: 2px;
}

.listings.home {
  margin-bottom: 30px;
}

.callout {
  font-size: 16px;
  width: 100%;
  border: 1px solid #f0f0f0;
  background: #b565a7;
  color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
}

.callout a {
  color: #FFFF00;
  text-decoration : underline;
}

.callout h3 {
  font-size: 19px;
  margin-bottom: 15px;
}

.callout p {
  font-size: 16px !important;
}

.nomargintop {
  margin-top: 0 !important;
}

.nomarginbottom {
  margin-bottom: 0 !important;
}

.callout.fade {
  background: #fafafa;
  border: 1px solid #f0f0f0;
  color: #000;
  margin-bottom: 0;
}

.callout.fade a {
  color: #b565a7;
}

.callout p:last-child {
  margin-bottom: 0;
}

.callout a:hover {
  text-decoration: underline;
}
.blocks-inner {
  margin:0 auto;
  margin-bottom: 30px;
}
.blocks ul {
  text-align:center;
  margin:0;
  padding:0;
}
.blocks li {
  display:inline-block;
}

.blocks li a {
  display:table-cell;
  vertical-align: middle;
  padding: 8px 12px;
  border-radius: 2px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  border: 1px solid #f0f0f0;
  color: #818896;
  background: #fafafa;
  width: 142px;
  height: 85px;
  vertical-align: middle;
  text-align: center;
}

.blocks li a:hover {
  text-decoration: none;
  background: #b565a7;
  color: #fff;
}

/*==========  Non-Mobile First Method  ==========*/
/* Large Devices, Wide Screens */
/* Medium Devices, Desktops */
@media only screen and (max-width: 991px) {
  /* PAGINATION */
  .navigate-page li {
    height: 25px;
    width: 25px;
  }
  .navigate-page li a {
    line-height: 25px;
    font-size: 14px;
  }
  .navigate-page li:first-child, .navigate-page li:last-child {
    width: 65px;
  }
}
/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .navigate-page {
    text-align: center;
    margin: 20px 0;
  }
}

.placeholdersjs {
  color: #a9a9a9;
}


#mobile-menu {
  display: none;
}

#mobile-nav-container {
  display: none;
}

@media only screen and (max-width: 991px) {
  #header {
    position: relative;
    padding: 5px;
  }

  #mobile-menu {
    margin: 0;
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 17px;
    display:block;
    width:25px;
    text-indent:-999px;
    height:15px;
    border-top:3px solid #b565a7;
    border-bottom:3px solid #b565a7;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
 
  #sidebar-privacy {
    display: none;
  }

  #mobile-menu:before {
    content:"";
    position:absolute; 
    top:3px;
    left:0;
    width:100%;
    border-top:3px solid #b565a7;
  }

  #mobile-nav-container {
    display: block;
    background: #d673c0;
  }

  #mobile-nav-container:before,
  #mobile-nav-container:after {
    display: table;
    content: " ";
  }
  #mobile-nav-container:after {
    clear: both;
  }

  #mobile-nav li {
    background: #d673c0;
    color: #fff;
    text-align: center;
    float: left;
    width: 33.33333%;
  }

  #mobile-nav li:hover {
    background: #b565a7;
  }

  #mobile-nav li a {
    color: #fff;
    display: block;
    padding: 5px 8px;
  }

  #mobile-nav li a:hover {
    text-decoration: none;
  }

  #header.nav-open {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }

  .listings hr {
    margin: 12px 0;
  }
}

.nomarginbottom {
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 720px) {
  #content p, .text, .text h4 {
    font-size: 16px;
  }

  .prev-post-title, .next-post-title {
    font-size: 16px;
  }

  .link-title-previous span.nav, .link-title-next span.nav {
    font-size: 14px;
  }
}

@media only screen and (max-width: 568px) {
  #mobile-nav li {
    width: 50%;
  }

  #sidebar-contact {
    display: none;
  }

  #logo img {
    height: 22px;
  }

  h2 {
    font-size: 22px;
    margin-bottom: 15px !important;
  }

  .listings hr {
    margin: 10px 0;
  }

  .meta-info {
    display: none;
  }

  .meta-info {
    margin: 10px 0 !important;
  }
  .meta-info a {
    font-size: 10px;
    padding: 4px;
  }

  #content {
    margin: 10px;
    padding: 10px !important;
  }

  .text {
    margin-bottom: 20px;
  }

  .text h3 {
    font-size: 18px;
    margin-bottom:15px;
    margin-top: 0;
  }

  .text p {
    margin-bottom: 14px;
  }

  #mobile-menu {
    top: 11px;
    border-top:2px solid #b565a7;
    border-bottom:2px solid #b565a7;
    height: 12px;
  }

  #mobile-menu:before {
    top:3px;
    border-top:2px solid #b565a7;
  }

  .post-navigation {
    margin-bottom: 20px;
    background: #b565a7;
  }

  .post-navigation span.nav {
    color: #fff;
  }

  .link-title-previous, .link-title-next {
    padding: 10px 0 10px 0;
  }
  .link-title-next {
    padding-right: 10px;
  }
  .link-title-previous {
    padding-left: 10px;
  }
  .link-title-previous span.nav, .link-title-next span.nav {
    font-size: 12px;
    line-height: 12px;
  }
  .next-post-title, .prev-post-title {
    display: none;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 15px;
    margin-top: 0;
  }

  .comments-area {
   margin-bottom: 20px;
  }

  .comments-area .comment-form h3 {
    margin-bottom: 15px;
  }

  form input[type="text"], form select {
    width: 100%;
  }

  .yes, .no, .errors {
    margin-bottom: 10px;
  }

  .errors .title {
    font-size: 16px;
    font-weight: 400;
  }

  .callout {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  #mobile-nav li {
    font-size: 14px;
  }

  .listings.home {
    margin-bottom: 20px;
  }

  .blocks li a {
      width: 130px !important;
  }

  .blocks-inner {
    margin-bottom: 10px;
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  #content, #sidebar {
    margin-top: 9px;
    margin-bottom: 9px;
  }

  #content {
    padding: 20px;
  }

  .meta-info {
    margin: 20px 0;
  }

  h2 {
    margin-bottom: 20px;
  }
}

label {
  font-weight: bold;
  display: block;
  padding-bottom: 5px;
}

label.inline {
  font-weight: normal;
  display: inline;
}

.has-error label {
  color: #eb7e73;
}

.has-error input, .has-error textarea {
  border: 1px solid #eb7e73;
}

.table {
  display: table;
}

.row {
  display: table-row;
}

.cell {
  display: table-cell; 
  padding: 2px;
}

.center {
  margin: 0 auto;
}

.btn-lg {
  width: 100%;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  visibility: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #acacac;
  background: rgba(0, 0, 0, .3);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#4C000000, endColorstr=#4C000000)";
  *zoom: 1;
}

.modal-close {
  position: fixed;
  top: 3%;
  right: 3%;
  z-index: 1000;
  padding: 0;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background: transparent;
  color: #34495e;
  font-size: 40px;
  line-height: normal;
  cursor: pointer;
  outline: 0;
}

.modal-content {
  margin: 0 auto;
  position: absolute;
  max-width: 95%;
  max-height: 90%;
  overflow-y: auto;
  background: #fff;
  opacity: 1;
  padding: 10px;
  border: 5px solid #b565a7;
}

.modal-content h4, .modal-content h3 {
  padding-bottom: 10px;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 22px;
}

.modal-content .title {
  text-align: center;
  white-space: nowrap;
}

.modal-content input {
  width: 50% !important;
  width: 49%\9 !important;
}

.modal-content input:first-child {
  margin-right: 5px;
  width: calc(50% - 5px) !important;
}

.modal-content select {
  width: 100%;
}

.modal-visible {
  visibility: visible !important;
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.modal-center {
  top: 50%;
  left: 50%;
  top: 30%\9;
  left: 30%\9;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#footer-share {
    z-index: 10;
    position: absolute;
    position: fixed;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
}

#footer-share a {
    display: block;
    border-radius: 0 !important;
    padding: 5px;
    float: left;
    width: 25%;
    margin: 0;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@media only screen and (max-width: 480px) {
  .hide-mobile-sm {
    display: none !important;
  }

  .modal-content {
    width: 95%;
  }

  .modal-close {
    top: 0;
    right: 0;
  }
}

#newsletter span {
  color: #999;
  cursor: default;
}

#newsletter span:hover {
  border-bottom: none;
}

#newsletter-email {
  max-width: 250px;
}